<template>
  <div>
    <a-form class="simiot-nested-search-form" :form="form" @submit="handleSearch">
      <a-row :gutter="24">
        <a-col :span="6" :xxl="6">
          <a-form-item label="VPDN分组ID">
            <a-input
              v-decorator="[
                'vpdn_id',
                {
                  normalize: this.$lodash.trim,
                },
              ]"
              allow-clear
            />
          </a-form-item>
        </a-col>

        <a-col :span="6" :xxl="6" v-show="expand">
          <a-form-item label="VPDN分组名称">
            <a-input
              v-decorator="[
                'name',
                {
                  normalize: this.$lodash.trim,
                },
              ]"
              allow-clear
            />
          </a-form-item>
        </a-col>

        <a-col :span="6" :xxl="6" v-show="expand">
          <a-form-item label="有效性">
            <a-select v-decorator="['effective', { initialValue: '1' }]" allow-clear>
              <a-select-option key="1" value="1"> 有效 </a-select-option>
              <a-select-option key="0" value="0"> 无效 </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="6" :xxl="6" v-show="expand">
          <a-form-item label="VPDN IP地址">
            <a-input
              v-decorator="[
                'ip',
                {
                  normalize: this.$lodash.trim,
                },
              ]"
              allow-clear
            />
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6">
          <a-form-item>
            <a-space>
              <a-button type="primary" html-type="submit"> 搜索 </a-button>
              <a-button @click="handleReset"> 刷新 </a-button>
              <a-button :style="{ fontSize: '12px', lineHeight: '32px' }" @click="toggle">
                更多条件
                <a-icon :type="expand ? 'up' : 'down'" />
              </a-button>
            </a-space>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>
<script>
import { findEffectiveSaleOptions } from '@/api/user'

export default {
  name: 'SearchVpdn',
  data() {
    return {
      expand: false,
      salesOptions: [],
      loadingSaleOptions: false,
      form: this.$form.createForm(this, { name: 'agent_search' })
    }
  },
  computed: {
    isShowSaleOption() {
      return this.$store.getters.userRole !== 'sale' && this.$store.getters.userRole !== 'sale_assistant'
    }
  },
  methods: {
    toggle() {
      this.expand = !this.expand
    },

    fetchSalesOptions() {
      if (this.salesOptions.length === 0) {
        findEffectiveSaleOptions().then((res) => {
          if (res.code === 0) {
            this.salesOptions = res.data
          }
          this.loadingSaleOptions = false
        })
      }
    },

    handleSearch(e) {
      e.preventDefault()
      this.$emit('submit', this.form.getFieldsValue())
    },

    handleReset() {
      this.form.resetFields()
      this.$emit('submit', {})
    }
  }
}
</script>
