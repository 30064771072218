<template>
  <div>
    <search-vpdn @submit="submitSearch" />

    <div class="simiot-table-operation-buttons">
      <a-space>
        <a-button
          type="primary"
          @click="isShowNewModal = true"
          v-if="isShowMenu('agents/vpdn_manage/new')"
        >新增</a-button>

        <update-vpdn-effective
          v-if="isShowMenu('agents/vpdn_manage/effective')"
          :selected-row-keys="selectedRowKeys"
          @completed="fetchData"
        />
      </a-space>
    </div>

    <a-table
      size="middle"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      :scroll="{ x: 'max-content' }"
      @change="sortChange"
      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      row-key="id"
    >
      <div slot="vpdn_id" slot-scope="vpdn_id, record">
        <div v-if="isShowMenu('agents/vpdn_manage/show')">
          <router-link :to="'/agents/' + agentId + '/vpdn_manage/' + record.id">
            {{ vpdn_id }}
          </router-link>
        </div>
        <div v-else>{{ vpdn_id }}</div>
      </div>

      <div slot="ips_count" slot-scope="text, record">
        <div v-if="isShowMenu('agents/vpdn_manage/show')">
          <div v-if="text" class="ips_count" @click="showIpsModal(record)">
            {{ text }}
          </div>
          <div v-else>
            {{ text }}
          </div>
        </div>
        <div v-else>{{ text }}</div>
      </div>

      <span
        slot="actions"
        v-if="isShowMenu('agents/vpdn_manage/edit')"
        slot-scope="text, record"
      >
        <a-space>
          <a @click="showEditModal(record.id)" v-if="isHasEditPermission">编辑</a>
        </a-space>
      </span>
    </a-table>

    <pagination
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

    <!-- 新增客户模态框 -->
    <new-vpdn
      v-if="isShowNewModal"
      :visible.sync="isShowNewModal"
      @completed="fetchData"
    />

    <!-- 编辑客户模态框 -->
    <edit-vpdn
      v-if="isShowEditModal"
      :visible.sync="isShowEditModal"
      :id="editingId"
      @completed="fetchData"
    />

    <!-- IP地址 -->
    <ips
      v-if="isShowIpsModal"
      :visible.sync="isShowIpsModal"
      :ips="ips"
      @completed="fetchData"
    />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination/index'
import { findVpdns } from '@/api/vpdn'
import { hasPermission } from '@/utils/permission'
import SearchVpdn from '@/views/agents/info/vpdn_manage/Search'

export default {
  name: 'VpdnsList',
  components: {
    Pagination,
    SearchVpdn,
    NewVpdn: () => import('@/views/agents/info/vpdn_manage/New'),
    EditVpdn: () => import('@/views/agents/info/vpdn_manage/Edit'),
    Ips: () => import('@/views/agents/info/vpdn_manage/Ips'),
    UpdateVpdnEffective: () => import('@/views/agents/info/vpdn_manage/UpdateEffective')
  },
  data() {
    return {
      data: [],
      loading: true,
      isShowNewModal: false,
      isShowEditModal: false,
      ips: [],
      isShowIpsModal: false,
      isShowAgenciesModal: false,
      isShowAgentId: 0,
      selectedRowKeys: [],
      editingId: 0, // 正在编辑的记录id
      pagination: {
        total_count: 0
      },
      query: {
        effective: '1'
      },
      sort: {
        sort_field: '',
        sort_order: ''
      }
    }
  },
  computed: {
    agentId() {
      return parseInt(this.$route.params.id)
    },
    columns() {
      return [
        {
          title: 'VPDN分组ID',
          dataIndex: 'vpdn_id',
          key: 'vpdn_id',
          fixed: 'left',
          width: 200,
          scopedSlots: { customRender: 'vpdn_id' }
        },
        {
          title: 'VPDN分组名称',
          dataIndex: 'name'
        },
        {
          title: 'VPDN IP个数',
          dataIndex: 'ips_count',
          sorter: true,
          sortOrder: this.sort.sort_field === 'ips_count' ? this.sort.sort_order : false,
          scopedSlots: { customRender: 'ips_count' }
        },
        {
          title: 'VPDN 关联卡数',
          dataIndex: 'cards_count',
          sorter: true,
          sortOrder: this.sort.sort_field === 'cards_count' ? this.sort.sort_order : false
        },
        {
          title: '有效性',
          dataIndex: 'effective'
        },
        {
          title: '描述',
          dataIndex: 'description'
        },
        {
          title: '创建时间',
          dataIndex: 'created_at',
          sorter: true,
          sortOrder: this.sort.sort_field === 'created_at' ? this.sort.sort_order : false
        },
        {
          title: '操作',
          dataIndex: 'actions',
          key: 'action',
          fixed: 'right',
          scopedSlots: { customRender: 'actions' }
        }
      ]
    },
    isHasEditPermission() {
      return hasPermission('agents/edit')
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    isShowMenu(menu) {
      return hasPermission(menu)
    },

    showEditModal(id) {
      this.editingId = id
      this.isShowEditModal = true
    },

    showIpsModal(record) {
      this.ips = JSON.parse(record.ips)
      this.isShowIpsModal = true
    },

    showAgenciesModal(id) {
      this.isShowAgentId = id
      this.isShowAgenciesModal = true
    },

    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },

    submitSearch(search) {
      // 重置排序
      this.sort = this.$options.data().sort
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign(
        {},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search
      )
      this.fetchData()
    },

    sortChange(pagination, filters, sorter) {
      this.sort.sort_field = sorter.field
      this.sort.sort_order = sorter.order
      // 跳转第一页
      this.query.page = 1
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      this.selectedRowKeys = []
      findVpdns(Object.assign({ agent_id: this.agentId }, this.query, this.sort)).then(
        (res) => {
          this.data = res.data
          this.pagination.total_count = res.pagination.total_count
          this.loading = false
        }
      )
    }
  }
}
</script>

<style scoped>
.ips_count {
  color: #3081d9;
  cursor: pointer;
}
</style>
